//------------------------------------------------------------------------
// Sortable Tables
//------------------------------------------------------------------------
export default class SortableTable {
  constructor(el) {
    this.el = el;
    this.table = this.el.querySelector('tbody') || this.el;
    this.th = this.el.querySelectorAll('th[data-sortable]');
    this.liveRegion = this.el.parentElement.querySelector('.DataTable-sortNote');

    if (!this.th) {
      return;
    }

    this.th.forEach((th) => {
      let asc = false;// placeholder for sort order

      // Replace originl child element (e.g. <span>) with a button for accessibility
      let child = th.firstElementChild;// <th> always has a child element on this site
      let button = document.createElement('button');
      button.setAttribute('type', 'button');
      // Move classes to button
      button.setAttribute('class', child.className);
      // Move “aria-label” to button
      if (child.hasAttribute('aria-label')) {
        button.setAttribute('aria-label', child.getAttribute('aria-label'));
      }
      // Add button to DOM
      child.parentNode.insertBefore(button, child);
      // Move contents of child element to the button
      button.innerHTML = child.innerHTML;
      // Remove the original child element
      child.remove();

      // Toggle sorting on button click
      button.addEventListener('click', (evt) => {
        // Ignore tooltip button clicks
        if (evt.target.classList.contains('Tooltip-toggle')) {
          return false;
        }

        asc = !asc;// toggle sort order (ascending initially)

        this.resetButtons();

        // Update “data-sort” attribute for styling purposes
        button.setAttribute('data-sort', asc ? 'asc' : 'desc');

        let sortDirectionName = asc ? 'ascending' : 'descending';

        // Update “aria-sort” on <th>
        th.setAttribute('aria-sort', sortDirectionName);

        // Update live region to alert screen-reader users
        // https://adrianroselli.com/2021/04/sortable-table-columns.html
        if (this.liveRegion) {
          this.liveRegion.innerHTML = `${button.textContent} sorted in ${sortDirectionName} order`;

          // Reset the text after a second, otherwise sorting another
          // column in the same direction won’t trigger the alert.
          setTimeout(() => {
            this.liveRegion.innerHTML = '';
          }, 1000);
        }

        // Sort the table rows
        // https://stackoverflow.com/a/49041392/673457
        Array.from(this.table.querySelectorAll('tr'))
          .sort(this.comparer(Array.from(th.parentNode.children).indexOf(th), asc))
          .forEach(tr => this.table.appendChild(tr));
      });
    });
  }

  // Reset “data-sort” attribute on buttons for styling purposes
  resetButtons() {
    this.el.querySelectorAll('th button').forEach((button) => {
      button.removeAttribute('data-sort');
      button.removeAttribute('aria-label');
    });
  }

  // Helper to get cell text
  // https://stackoverflow.com/a/49041392/673457
  getCellValue(tr, idx) {
    return tr.children[idx].innerText || tr.children[idx].textContent;
  }

  // Returns a function responsible for sorting a specific column index
  // (idx = columnIndex, asc = ascending order?)
  // https://stackoverflow.com/a/49041392/673457
  // https://stackoverflow.com/a/53880407/673457
  comparer(idx, asc) {
    const self = this;

    // This is used by the array.sort() function
    return function(a, b) {

      // This is a transient function that allows passing
      // different order of args based on the “asc” order.
      return function(v1, v2) {

        // Sort based on a numeric or localeCompare() based on type
        return (v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2))
          ? v1 - v2
          : v1.toString().localeCompare(v2);

      }(self.getCellValue(asc ? a : b, idx), self.getCellValue(asc ? b : a, idx));
    }
  }
}
