// Basic markdown-to-HTML conversion for bold, italics, and links

const markdownRegex = [
  // Bold
  {
    regex: /\*\*(.+?)\*\*/g,
    replace: "<b>$1</b>"
  },
  // Italic
  {
    regex: /\*(.+?)\*/g,
    replace: "<i>$1</i>"
    // NOTE: Use this more complex regex if bold test isn’t executed first
    // regex: /([^\*])\*([^\*]+?[^\*])\*([^\*])/g,
    // replace: "$1<i>$2</i>$3"
  },
  // Links
  {
    regex: /\[(.+?)\]\((.+?)\)/g,
    replace: '<a href="$2" data-no-swup>$1</a>'
  }
];

export default function(string) {
  markdownRegex.forEach(test => {
    string = string.replace(test.regex, test.replace);
  });

  return string;
};
