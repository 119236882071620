//------------------------------------------------------------------------
// Data table expandable sections and “expand all” toggle
//------------------------------------------------------------------------
import ExpandToggle from '@threespot/expand-toggle';

export default class DataTable {
  constructor(el) {
    this.el = el;
    this.toggleEls = this.el.querySelectorAll('[data-expands]');
    this.toggles = [];
    this.expandAllToggle = this.el.querySelector('.DataTable-toggleAll');
    this.expandAllToggleText = this.expandAllToggle.querySelector('[data-expands-text]') || false;
    this.expandAllToggleShouldExpand = true;

    this.toggleEls.forEach((el) => {
      // Initialize sections toggles and save to this.toggles array
      let toggle = new ExpandToggle(el);
      this.toggles.push(toggle);

      // Update the “expand all” toggle after a sections is expanded or collapsed
      if (this.expandAllToggle) {
        toggle.on('expand', () => {
          this.updateExpandAll();
        });

        toggle.on('collapse', () => {
          this.updateExpandAll();
        });
      }
    });

    // Initialize the “expand all” toggle
    if (this.expandAllToggle) {
      this.expandAllToggle.setAttribute('aria-expanded', 'false');

      // Save default toggle text
      this.expandAllDefaultText = this.expandAllToggleText.textContent.trim();
      this.expandAllExpandedText = this.expandAllToggleText.getAttribute('data-expands-text');

      this.expandAllToggle.addEventListener('click', (evt) => {
        this.toggleAll(evt);
      });
    }
  }

  // Check if we need to update the text and functionality of the “expand all” toggle
  updateExpandAll(expandableEvent) {
    let expandedStates = [];

    // Check the “aria-expanded” value of each section
    this.toggleEls.forEach((el) => {
      // Note: Since getAttribute() returns a string we’re comparing to 'true' to convert to bool
      expandedStates.push(el.getAttribute('aria-expanded') == 'true');
    });

    // Check if all values in the array are true (i.e. all sections are expanded)
    if (expandedStates.every(x => x)) {
      // If all sections are expanded, switch to “collapse all”
      this.expandAllToggleShouldExpand = false;
      this.expandAllToggleText.textContent = this.expandAllExpandedText;
      this.expandAllToggle.classList.add('is-active');// for icon styling
    } else {
      // Default to “expand all” otherwise
      this.expandAllToggleShouldExpand = true;
      this.expandAllToggleText.textContent = this.expandAllDefaultText;
      this.expandAllToggle.classList.remove('is-active');// for icon styling
    }
  }

  expandAll() {
    // Update button state
    this.expandAllToggle.setAttribute('aria-expanded', 'true');

    this.toggles.forEach((toggle) => {
      toggle.expand();
    });
  }

  collapseAll() {
    // Update button state
    this.expandAllToggle.setAttribute('aria-expanded', 'false');

    this.toggles.forEach((toggle) => {
      toggle.collapse();
    });
  }

  toggleAll(evt) {
    evt.preventDefault();// just in case we forget to add `type="button"` to toggle

    if (this.expandAllToggleShouldExpand) {
      this.expandAll();
    } else {
      this.collapseAll();
    }
  }

}

