//------------------------------------------------------------------------
// Country score choropleth map
// https://github.com/Threespot/malala-report-cards/issues/9
// https://docs.mapbox.com/mapbox-gl-js/example/data-join/
//
// Note: Expects `mapData` variable to be inlined in the <head>
//------------------------------------------------------------------------
/* global mapboxgl, mapData, mapIndicator, MAPBOX_PUBLIC_TOKEN */
export default class CountriesMap {
  constructor(selector) {
    this.el = document.querySelector(selector);
    this.data = window.mapData;
    this.indicator = window.mapIndicator;
    this.MAPBOX_PUBLIC_TOKEN = window.MAPBOX_PUBLIC_TOKEN;
    this.hoveredCountryId = null;// placeholder for hover effect

    // Make sure we have the required info
    if (!this.el || !this.data || !this.indicator || !this.MAPBOX_PUBLIC_TOKEN) {
      return false;
    }

    // Note: Since 100/8 = 12.5, we’re alternating adding 12 and 11
    // e.g. 12 + 11 + 12 + 11 + 12 + 11 + 12 + 12 == 100
    // https://docs.google.com/spreadsheets/d/1bRQI8IRHNkKlY3cZsHnDqCkTJ_6R9Pr0gFVDKmShGBk/edit#gid=91975749
    this.scoreRanges = [13, 25, 38, 50, 63, 75, 88, 100];

    this.colorScale = [
      '#74331b',
      '#b04a28',
      '#ee6536',
      '#e3904c',
      '#d8b767',
      '#90af80',
      '#0da794',
      '#0a7b6d',
    ];

    // Check if Mapbox JS has been loaded before initializing
    if (typeof mapboxgl !== 'undefined') {
      this.init();
    }
  }

  init() {
    this.buildMap();
    this.buildLegend();
  }

  buildMap() {
    mapboxgl.accessToken = this.MAPBOX_PUBLIC_TOKEN;

    this.map = new mapboxgl.Map({
      container: 'map',
      // style: 'mapbox://styles/mapbox/light-v11',
      // https://studio.mapbox.com/styles/globaladvomf/cljd68ric001y01qr3tgs2n2t/edit/#3.09/12.24/35.42
      style: 'mapbox://styles/globaladvomf/cljd68ric001y01qr3tgs2n2t',
      // Projections https://docs.mapbox.com/mapbox-gl-js/guides/projections/
      projection: 'mercator',// equirectangular
      // Require Command + scroll to zoom like in Google Maps
      // https://github.com/mapbox/mapbox-gl-js/issues/6884#issuecomment-948156016
      cooperativeGestures: true,
      // center: [],
      // zoom: 1.27,
    });

    // Auto adjust map to fit the entire world, except the poles
    this.map.fitBounds([
      [-161.6028118769551, 70.97045950703597],
      [178.690350250223, -46.26804400005425]
    ], {
      padding: 15
    });

    this.map.on('load', () => {
      // Add source for country polygons using the Mapbox Countries tileset
      // The polygons contain an ISO 3166 alpha-3 code which can be used to for joining the data
      // https://docs.mapbox.com/vector-tiles/reference/mapbox-countries-v1
      this.map.addSource('countries', {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1'
      });

      // Build a GL match expression that defines the color for every vector tile feature
      // Use the ISO 3166-1 alpha 3 code as the lookup key for the country shape
      const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];

      // Calculate color values for each country based on 'hdi' value
      for (const row of this.data) {
        // Find associated color for current score
        let score = Math.round(row[this.indicator] * 100);
        let colorIndex = this.scoreRanges.findIndex((element) => element > score);
        matchExpression.push(row['code'], this.colorScale[colorIndex]);
      }

      // Last value is the default, used where there is no data
      matchExpression.push('rgba(0, 0, 0, 0)');

      // The mapbox.country-boundaries-v1 tileset includes multiple polygons for some
      // countries with disputed borders.  The following expression filters the
      // map view to show the "US" perspective of borders for disputed countries.
      // Other world views are available, for more details, see the documentation
      // on the "worldview" feature property at
      // https://docs.mapbox.com/data/tilesets/reference/mapbox-countries-v1/#--polygon---worldview-text
      const WORLDVIEW = "US";
      const worldview_filter = [
        "all",
        ["==", ["get", "disputed"], "false"],
        ["any",
          [ "==", "all", ["get", "worldview"]],
          ["in", WORLDVIEW, ["get", "worldview"]]
        ]
      ];

      // Add layer from the vector tile source to create the choropleth
      // Insert it below the 'admin-1-boundary-bg' layer in the style
      this.map.addLayer(
        {
          'id': 'countries-join',
          'type': 'fill',
          'source': 'countries',
          'source-layer': 'country_boundaries',
          'paint': {
            'fill-color': matchExpression
          },
          'filter': worldview_filter
        },
        'admin-0-boundary-bg'
      );

      // Add layer for country borders and use a feature-state expression to set opacity on hover
      // https://docs.mapbox.com/mapbox-gl-js/example/hover-styles/
      this.map.addLayer({
        'id': 'country-border',
        'type': 'line',
        'source': 'countries',
        'source-layer': 'country_boundaries',
        'layout': {
          'line-cap': 'butt',
          'line-join': 'miter',
        },
        'paint': {
          'line-color': '#f5f3f2',
          'line-width': [
            "interpolate",
            ["linear"],
            ["zoom"],
            3,
            2,
            12,
            8
          ],
          'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0
          ],
          // FYI we can’t transition feature states yet so this has no effect
          // https://github.com/mapbox/mapbox-gl-js/issues/4045
          // https://github.com/mapbox/mapbox-gl-js/issues/11748
          'line-opacity-transition': {
            'duration': 150,
            'delay': 0
          }
        }
      });
    });

    // Reset mouse cursor when leaving land
    this.map.on('mouseleave', 'countries-join', (event) => {
      this.resetCountry();
    });

    // Highlight countries on hover
    this.map.on('mousemove', 'countries-join', (event) => {
      // If entering a new country…
      if (this.hoveredCountryId !== event.features[0].id) {
        // Reset previously highlighted country
        if (this.hoveredCountryId) {
          this.resetCountry();
        }

        // Check if coutry has data (i.e. fill color isn’t transparent)
        if (event.features[0].layer.paint["fill-color"].a !== 0) {
          this.highlightCountry(event.features[0].id);
        }
      }
    });

    // Show tooltip on click
    this.map.on('click', 'countries-join', (event) => {
      // Get layer of clicked point (i.e. country)
      const features = this.map.queryRenderedFeatures(event.point, {
        layers: ['countries-join']
      });

      // Update tooltip
      if (features.length) {
        // Get the country code of the clicked point
        const country_code = features[0].properties.iso_3166_1_alpha_3;

        // Find the matching country code in our data
        const currentCountry = this.data.find((country) => country.code == country_code);

        if (!currentCountry) {
          return;
        }

        // Show tooltip popup
        // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/

        // Get click event coordinates
        const coordinates = [event.lngLat.lng, event.lngLat.lat];

        // In case the map is zoomed out enough that a country appears twice,
        // make sure the popup is over the correct one.
        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Create popup
        const popup = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<h2 class="mapboxgl-popup-title">${currentCountry.name}</h2>
            <p class="mapboxgl-popup-score ${!currentCountry.sdg_score ? 'u-hide' : ''}">
              <span class="mapboxgl-popup-score-label">SDG Score</span>
              ${Math.round(currentCountry.sdg_score * 100)}
            </p>
            <p class="mapboxgl-popup-score ${!currentCountry.policy_score ? 'u-hide' : ''}">
              <span class="mapboxgl-popup-score-label">Policy Score</span>
              ${Math.round(currentCountry.policy_score * 100)}
            </p>
            <p class="mapboxgl-popup-score ${!currentCountry.overall_score ? 'u-hide' : ''}">
              <span class="mapboxgl-popup-score-label">Avg. Score</span>
              ${Math.round(currentCountry.overall_score * 100)}
            </p>
            <p class="mapboxgl-popup-score ${!currentCountry.donor_score ? 'u-hide' : ''}">
              <span class="mapboxgl-popup-score-label">Donor Score</span>
              ${Math.round(currentCountry.donor_score * 100)}
            </p>
            <a class="mapboxgl-popup-cta" href="${currentCountry.url}">
              Explore ${currentCountry.name} Data
            </a>`
          )
          .addTo(this.map);
      }
    });

    // Dev helpers
    // this.map.on('click', 'countries-join', (event) => {
    //   // Log click target coordinates
    //   console.log([event.lngLat.lng, event.lngLat.lat]);
    //
    //   // Log zoom level
    //   console.log('zoom:', Math.round(this.map.getZoom() * 1000) / 1000);
    //
    //   // Log the center coordinates of map
    //   let bounds = this.map.getBounds();
    //   let lng = (bounds._ne.lng + bounds._sw.lng) / 2;
    //   let lat = (bounds._ne.lat + bounds._sw.lat) / 2;
    //   console.log({ center: [lng, lat] });
    // });
  }

  buildLegend() {
    const list = document.querySelector('.MapLegend-list');
    let listHtml = '';

    this.colorScale.forEach((color, index) => {
      let range = index == 0
        ? `0–${this.scoreRanges[index]}`
        : `${this.scoreRanges[index - 1] + 1}–${this.scoreRanges[index]}`;

      listHtml += `<li class="MapLegend-item" style="--bg-color: ${color};">${range}</li>`;
    });

    list.innerHTML = listHtml;
  }

  // Highlight country on hover
  highlightCountry(id) {
    this.hoveredCountryId = id;
    this.map.setFeatureState(
      {
        source: 'countries',
        sourceLayer: 'country_boundaries',
        id: this.hoveredCountryId
      },
      {
        hover: true
      }
    );

    // Set mouse cursor to pointer
    this.map.getCanvas().style.cursor = 'pointer';
  }

  // Reset previously highlighted country
  resetCountry() {
    this.map.setFeatureState(
      {
        source: 'countries',
        sourceLayer: 'country_boundaries',
        id: this.hoveredCountryId
      },
      {
        hover: false
      }
    );

    // Set mouse cursor back to default
    this.map.getCanvas().style.cursor = '';
  }
}
