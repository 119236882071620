// Emit “font-loaded” event so expandable components know to recalc their height
document.fonts.ready.then(() => {
  let d = document.documentElement;
  let fontEvent = new CustomEvent('fonts-loaded');
  d.dispatchEvent(fontEvent);
  d.classList.add('font-loaded');
});

// Detect input method in order to hide outlines in an accessible manner
// https://github.com/ten1seven/what-input
import 'what-input';

// Country menu using Accessible Autocomplete
// https://github.com/alphagov/accessible-autocomplete
import './modules/country-menu';

// Scroll-triggered animations when element enteres the viewport
// e.g. “The Challenge” heading
import './modules/scroll-observer';

// Scroll-triggered animations when the element crosses the middle of the viewport
// e.g. “Key Takeaways” items
import './modules/scroll-observer-center';

// Mobile nav toggle
import NavToggle from './modules/nav-mobile-toggle';
const navToggle = new NavToggle('[data-expands="nav"]');

// Nav menu toggle (e.g “Explore Report Cards”)
import NavMenuToggle from './modules/nav-menu-toggle';
const navMenuToggle = new NavMenuToggle('[data-expands="nav-menu"]');

// Data table expandable sections and “expand all” toggle
import DataTable from './modules/data-table';
document.querySelectorAll('.DataTable.is-collapsible').forEach(el => new DataTable(el));

// Sortable tables
import SortableTable from './modules/sortable-table';
document.querySelectorAll('table.is-sortable').forEach(el => new SortableTable(el));

// Tooltips
import Tooltip from './modules/tooltips';
document.querySelectorAll('[data-tooltip][title]').forEach(el => new Tooltip(el, {
  appendLabelText: 'toggle tooltip',
  classes: el.closest('th') ? 'align-bottom' : '',// bottom align in expsndable tables
  gutter: 10, // minimum space between tooltip and edge of screen
  // prepend: ''
}));

// Country score choropleth map
import CountriesMap from './modules/countries-map';
const countriesMap = new CountriesMap('#map');
