//------------------------------------------------------------------------
// Mobile nav toggle
//------------------------------------------------------------------------
import ExpandToggle from '@threespot/expand-toggle';

export default class NavToggle {
  constructor(selector) {
    this.el = document.querySelector(selector);

    if (!this.el) {
      console.warn(`Unable to initialize nav toggle, no matching element for “${selector}”`);
      return false;
    }

    // Get breakpoint from CSS var
    this.mobileNavBreakpoint = getComputedStyle(document.documentElement).getPropertyValue('--layout-nav-bp');
    this.mediaQueryList = window.matchMedia(`(min-width: ${this.mobileNavBreakpoint})`);

    // Listen for breakpoint change
    this.mediaQueryList.addEventListener('change', (evt) => {
      if (evt.matches) {
        this.destroy();
      } else {
        this.init();
      }
    });

    // Init on load
    if (!this.mediaQueryList.matches) {
      this.init();
    }
  }

  windowClickHandler(evt) {
    if (!evt.target.closest('.Header')) {
      this.navToggle.collapse();
    }
  }

  init() {
    this.navToggle = new ExpandToggle(this.el);

    // Close if click outside of header
    this.windowClick = this.windowClickHandler.bind(this);
    window.addEventListener('click', this.windowClick);
  }

  destroy() {
    this.navToggle.destroy();
    window.removeEventListener('click', this.windowClick);
  }
}
