//------------------------------------------------------------------------
// Country menu using Accessible Autocomplete
// https://github.com/alphagov/accessible-autocomplete
//
// Note: If country aliases are required in the future, there’s a more robust
//       version specifically for countries and territories.
//       https://github.com/alphagov/govuk-country-and-territory-autocomplete/
//------------------------------------------------------------------------
import accessibleAutocomplete from 'accessible-autocomplete';

const countryMenu = document.querySelector('#country-menu');

if (countryMenu) {
  // We’re initializing on an exisitng <select> menu so it still works
  // without JS (relies on query param and redirect rule in netlify.toml)
  // https://github.com/alphagov/accessible-autocomplete#progressive-enhancement
  accessibleAutocomplete.enhanceSelectElement({
    autoselect: true,// auto selects first match so users just have to press enter
    // defaultValue: '',
    displayMenu: 'overlay',
    // minLength: 2,
    placeholder: 'Search by country',
    selectElement: countryMenu,
  });

  // Override button functionality to navigate to country page directly
  // instead of relying on Netlify redirect rule (i.e. no-js fallback)
  countryMenu.closest('form').addEventListener('submit', (evt) => {
    evt.preventDefault();
    if (countryMenu.value) {
      window.location.href = '/countries/' + countryMenu.value;
    }
  });

  // Clear field when using the back button in Firefox
  // https://github.com/Threespot/malala-report-cards/issues/42
  window.addEventListener('pageshow', () => {
    let countryInput = document.querySelector('#country-menu');
    if (countryInput) {
      countryInput.value = '';
    }
  });
}
