// Detect when elements have scorlled into the viewport so we can trigger animations
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('start-animation');
    }
  });
},
{
  // Add negative bottom offset to show some of the element before the animation starts
  rootMargin: '0px 0px -80px 0px',
  threshold: [0]
});

document.querySelectorAll('[data-animate]').forEach((el) => observer.observe(el));
