//------------------------------------------------------------------------
// Nav drop-down menu toggle (e.g. “Explore Report Cards”)
//------------------------------------------------------------------------
import ExpandToggle from '@threespot/expand-toggle';

export default class NavMenuToggle {
  constructor(selector) {
    this.toggleEl = document.querySelector(selector);

    if (!this.toggleEl) {
      return
    }

    this.toggle = new ExpandToggle(this.toggleEl);
  }
}
