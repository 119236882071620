//------------------------------------------------------------------------
// Tooltips
//
// Based on https://inclusive-components.design/tooltips-toggletips/
//
// Works by injecting the tooltip contents into a wrapper with role="status"
// and then removing the content from the DOM when hidden. This allows
// screen readers to announce the tooltip text right away.
//
//   <span data-tooltip title="{{ def }}">…</span>
//
//------------------------------------------------------------------------
"use strict";
import expandMarkdown from "./expand-markdown";

/**
 * Build accessible tooltip
 * @param {HTMLElement} el - Tooltip DOM node
 * @param {Object} opts - Options
 * @param {string} [opts.classes=""] - String of class names to add to tooltip wrapper
 * @param {number} [opts.gutter=0] - Gutter space around main content well
 * @param {string} [opts.prepend=""] - HTML string to prepend to tooltip content
 * @param {string} [opts.append=""] - HTML string to append to tooltip content
 * @param {string} [opts.appendLabelText=""] - String to append to “aria-label”
 */
export default class Tooltip {
  constructor(el, opts) {
    this.el = el;
    this.tooltipContentSource = this.el.getAttribute("title");
    this.navBreakpoint = this.getCssVar('--layout-nav-width');

    if (!this.tooltipContentSource) {
      console.warn(`“${this.el.textContent.trim()}” tooltip has no “title” content`);
      return false;
    } else {
      this.tooltipContentSource = `<span class="Tooltip-description">${expandMarkdown(this.tooltipContentSource)}</span>`;
    }

    // Use Object.assign() to merge “opts” object with default values in this.options
    this.options = Object.assign(
      {},
      {
        classes: "",
        gutter: 0,
        prepend: "",
        append: "",
        appendLabelText: false
      },
      opts
    );

    // Build tooltip, add attributes
    this.buildTooltip();

    // Bind event listeners
    this.bindEvents();

    // Determine tooltip position on load and update “data-align” attribute
    window.requestAnimationFrame(this.updatePosition.bind(this));
  }

  // Debounce function
  // https://www.joshwcomeau.com/snippets/javascript/debounce/
  debounce(callback, wait) {
    let timeoutId = null;

    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  }

  // Generate the following markup:
  //   <span class="Tooltip">
  //     <a class="Tooltip-toggle" href="#" role="button" data-no-swup>Toggle text</a>
  //     <span class="Tooltip-content" role="status"></span>
  //   </span>
  buildTooltip() {
    // Create the tooltip parent element
    // (required to position the tooltip relative to toggle)
    this.tooltipWrap = document.createElement("span");
    this.tooltipWrap.className =
      "Tooltip" + (this.options.classes ? " " + this.options.classes : "");

    // Create the toggle button, add attributes
    // FYI, <button> would be a better choice than <a> except button
    // text can’t wrap like link text, it forces a line break.
    //
    // Note: Don’t copy the “title” attribute since we don’t need it
    //       anymore and it may be announced by some screen readers.
    let toggle = document.createElement("a");
    toggle.href = "#"; // required to style :hover/:focus/:active states
    toggle.className = "Tooltip-toggle " + this.el.className;
    toggle.setAttribute("role", "button");
    toggle.setAttribute("data-no-swup", "");// only needed when using Swup.js

    if (this.options.appendLabelText) {
      // Use “aria-label” text to indicate what the button does for screen-reader users
      toggle.setAttribute(
        "aria-label",
        this.el.textContent + " " + this.options.appendLabelText
      );
    }

    // Copy original element’s innerHTML to the new toggle
    toggle.innerHTML = this.el.innerHTML;

    // Add toggle to wrapper
    this.tooltipWrap.appendChild(toggle);

    // Create the tooltip content wrapper
    this.tooltipContent = document.createElement("span");
    this.tooltipContent.className = "Tooltip-content";
    // Use role="status" to create a “live region” that will
    // be read immdeiately by screen readers when updated.
    this.tooltipContent.setAttribute("role", "status");

    // Add tooltip text content
    this.tooltipText = document.createElement("span");
    this.tooltipText.className = "Tooltip-inner";
    this.tooltipText.setAttribute("aria-hidden", "true");
    this.tooltipText.innerHTML = this.options.prepend + this.tooltipContentSource + this.options.append;

    // Add text to tooltip content wrapper
    this.tooltipContent.appendChild(this.tooltipText);

    // Add to toggle wrapper
    this.tooltipWrap.appendChild(this.tooltipContent);

    // Insert new markup before the original element
    this.el.parentNode.insertBefore(this.tooltipWrap, this.el);

    // Remove the original element
    this.el.parentNode.removeChild(this.el);

    // Remap toggle to this.el now that the original element is gone
    this.el = toggle;
  }

  bindEvents() {
    // Toggle on click
    this.el.addEventListener("click", (evt) => {
      evt.preventDefault(); // since toggle is a link, we need this to avoid adding “#” to the address bar
      this.toggle();
    });

    // Close tooltip if click/tap off of it
    const eventType = "ontouchend" in window ? "touchend" : "click";
    window.addEventListener(eventType, evt => {
      if (!this.tooltipWrap.contains(evt.target)) {
        this.hideTooltip();
      }
    }, { passive: true });

    // Close tooltip with escape key
    window.addEventListener("keydown", evt => {
      if ((evt.which || evt.keyCode) === 27) {
        this.hideTooltip();
      }
    });

    // Hide on resize, recalc position
    this.resizeHandler = this.debounce(() => {
          // Due to debounce() it’s possible for this to run after destroy() has been called.
          // To avoid this edge case, check “this.hasInitialized” first.
          if (this.hasInitialized) {
            window.requestAnimationFrame(this.updateExpandedHeight.bind(this));
          }
        }, 100).bind(this);

    window.addEventListener("resize", this.debounce(() => {
      if (this.isOpen) {
        this.hideTooltip();
      }
      window.requestAnimationFrame(this.updatePosition.bind(this));
    }, 150).bind(this));

    // Hide on resize, recalc position
    window.addEventListener("scroll", this.debounce(() => {
      // Update position of closed tooltips
      if (!this.isOpen) {
        this.updatePosition();
      } else {
        // For open tooltips, use var to trigger recalc after closing
        this.didScroll = true;
      }
    }, 50).bind(this));

    // NOTE: Changing the font shouldn’t have any impact on positioning
    //       since we only care about horizontal positioning.
    //       However, leaving commented out in case it’s needed in the future.
    //
    // Update tooltip position after web fonts have loaded
    // document.documentElement.addEventListener("fonts-loaded", () => {
    //   window.requestAnimationFrame(this.updatePosition.bind(this));
    // });
  }

  isOpen() {
    return this.tooltipText.getAttribute("aria-hidden") == "false";
  }

  showTooltip() {
    this.tooltipContent.classList.add("is-active");
    this.tooltipText.setAttribute("aria-hidden", "false");
  }

  hideTooltip() {
    this.tooltipContent.classList.remove("is-active");
    this.tooltipText.setAttribute("aria-hidden", "true");

    // Recalc position if page was scrolled while tooltip was open
    if (this.didScroll) {
      this.updatePosition();
      this.didScroll = false;
    }
  }

  toggle() {
    if (this.isOpen()) {
      this.hideTooltip();
    } else {
      this.showTooltip();
    }
  }

  updatePosition() {
    // Reset alignment classes/attributes, centers tooltip over toggle
    this.tooltipWrap.classList.remove("is-fullwidth");
    this.tooltipContent.setAttribute("data-align", "");
    // Disable CSS transitions so we don’t have to wait for them to complete before measuring the tooltip
    this.tooltipText.style.transition = "none";
    // Use setTimeout to force browser to recalc styles first
    window.setTimeout(this.calculatePosition.bind(this), 0);
  }

  // Returns an integer equal to the CSS pixel value (will convert rem to px)
  getCssVar(name) {
    let cssValue = getComputedStyle(document.documentElement).getPropertyValue(name);
    cssValue = cssValue.trim();

    if (!cssValue.length) {
      return false;
    }

    // Convert to integer (will ignore CSS units)
    let value = parseInt(cssValue, 10);

    // Convert rem to px
    if (cssValue.indexOf('rem') > 0) {
      // Get root element font size
      let rootFontSize = parseInt(getComputedStyle(document.documentElement).fontSize, 10);
      // Convert to px (but as an integer, no units)
      return value * rootFontSize;
    }

    return value;
  }

  isDesktop() {
    return window.innerWidth >= this.navBreakpoint;
  }

  calculatePosition() {
    let desktopNavWidth = this.getCssVar('--layout-nav-width');
    let headerHeight = this.getCssVar('--layout-header-height');

    let bodyWidth = window.innerWidth - desktopNavWidth - (this.options.gutter * 2);
    let bodyRightCutoff = window.innerWidth - desktopNavWidth - this.options.gutter;

    // Toggle text dimensions
    let toggleBoundingRect = this.el.getBoundingClientRect();
    let toggleLeftOffset = toggleBoundingRect.left;
    let toggleRightOffset = toggleBoundingRect.right;

    // Tooltip dimensions
    let tooltipBoundingRect = this.tooltipContent.getBoundingClientRect();
    let tooltipLeftOffset = tooltipBoundingRect.left;
    let tooltipRightOffset = tooltipBoundingRect.right;
    let tooltipTopOffset = tooltipBoundingRect.top;
    let tooltipWidth = tooltipBoundingRect.width;

    let cutoffRight = tooltipRightOffset > bodyRightCutoff;
    // In desktop layout we need to account for the left nav menu (width equals “headerHeight”)
    let cutoffLeft = tooltipLeftOffset < (this.isDesktop() ? this.options.gutter + headerHeight : this.options.gutter);
    let cutoffTop = (tooltipTopOffset - headerHeight) < 0;


    // Re-enable transitions
    this.tooltipText.style.removeProperty("transition");

    // Set vertical alignment
    let vertAlignment = cutoffTop ? "bottom" : "top";

    // If sides are not cutoff, just set the vertical alignment
    if (!cutoffRight && !cutoffLeft) {
      this.tooltipContent.setAttribute("data-align", vertAlignment);
      return false;
    }

    // If right side is cutoff…
    if (cutoffRight) {
      // …check if left side would fit before right aligning to toggle
      if (tooltipWidth + this.options.gutter <= toggleRightOffset) {
        this.tooltipContent.setAttribute("data-align", "right " + vertAlignment);
        return false;
      }
    }

    // If left side is cutoff…
    if (cutoffLeft) {
      // …check if right side would fit before left aligning to toggle
      if (
        toggleLeftOffset + tooltipWidth <=
        window.innerWidth - this.options.gutter
      ) {
        this.tooltipContent.setAttribute("data-align", "left " + vertAlignment);
        return false;
      }
    }

    // Tooltip can’t be aligned to toggle so make it fullwidth
    this.tooltipWrap.classList.add("is-fullwidth");
    this.tooltipContent.setAttribute("data-align", "full " + vertAlignment);
  }
}
