// Detect when elements have scorlled into the viewport so we can trigger animations
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('start-animation');
    }
  });
},
{
  // Delay isIntersecting until element scrolls past the middle of the viewport
  rootMargin: '0px 0px -50% 0px',
  threshold: [0]
});

document.querySelectorAll('[data-animate-center]').forEach((el) => observer.observe(el));
